import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { LeadNote, LeadNoteCreate, GetLeadNoteParams, LeadReplyNote } from '@core/models/interfaces/lead';
import { NoteGroup, NoteStatus, NoteStatusCreate } from '@core/models/interfaces/note';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  private baseUrl = `${environment.API_URL}/crm`;
  private noteUrl = `${this.baseUrl}/notes`;
  private noteStatusUrl = `${this.baseUrl}/note-status`;

  constructor(private httpClient: HttpClient) {}

  // LEAD NOTE
  getLeadNoteById(id: string) {
    return this.httpClient.get<ResponseApi<LeadNote>>(`${this.noteUrl}/${id}`);
  }

  updateLeadNoteById(id: string, body: LeadNoteCreate) {
    return this.httpClient.put<ResponseApi<LeadNote>>(`${this.noteUrl}/${id}`, body);
  }

  deleteLeadNoteById(id: string) {
    return this.httpClient.delete(`${this.noteUrl}/${id}`);
  }

  getLeadNotes(params: GetLeadNoteParams) {
    let queryParams = new HttpParams();
    if (params.leadId !== undefined) {
      queryParams = queryParams.append('leadId', params.leadId);
    }

    if (params.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params.statusIds !== undefined) {
      for (let statusId of params.statusIds) {
        queryParams = queryParams.append('statusIds', statusId);
      }
    }

    if (params.createdBys !== undefined) {
      for (let createdById of params.createdBys) {
        queryParams = queryParams.append('createdBys', createdById);
      }
    }

    if (params.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    if (params.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    return this.httpClient.get<ResponseListApi<LeadNote>>(`${this.noteUrl}`, {
      params: queryParams
    });
  }

  getLeadNotesGroupBy(params: GetLeadNoteParams) {
    let queryParams = new HttpParams();
    if (params.createdBys !== undefined) {
      for (let createdById of params.createdBys) {
        queryParams = queryParams.append('createdBys', createdById);
      }
    }

    if (params.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    if (params.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    if (params.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params.statusIds !== undefined) {
      for (let statusId of params.statusIds) {
        queryParams = queryParams.append('statusIds', statusId);
      }
    }

    return this.httpClient.get<ResponseListApi<NoteGroup>>(`${this.noteUrl}/group/lead`, {
      params: queryParams
    });
  }

  getNotePageByLeadId(params: GetLeadNoteParams) {
    let queryParams = new HttpParams();

    if (params.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params.statusIds !== undefined) {
      for (let statusId of params.statusIds) {
        queryParams = queryParams.append('statusIds', statusId);
      }
    }

    if (params.createdBys !== undefined) {
      for (let createdById of params.createdBys) {
        queryParams = queryParams.append('createdBys', createdById);
      }
    }

    if (params.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    if (params.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    return this.httpClient.get<ResponseListApi<LeadNote>>(`${this.noteUrl}/lead/${params.leadId}`, {
      params: queryParams
    });
  }

  createLeadNote(body: LeadNoteCreate) {
    return this.httpClient.post<ResponseApi<LeadNote>>(`${this.noteUrl}`, body);
  }

  replyLeadNote(parentId: string, message: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<ResponseApi<LeadReplyNote>>(`${this.noteUrl}/reply/${parentId}`, message, {
      headers: headers
    });
  }

  // NOTE STATUS
  getNoteStatusById(id: string) {
    return this.httpClient.get<ResponseApi<NoteStatus>>(`${this.noteStatusUrl}/${id}`);
  }

  updateNoteStatusById(id: string, body: NoteStatusCreate) {
    return this.httpClient.put<ResponseApi<NoteStatus>>(`${this.noteStatusUrl}/${id}`, body);
  }

  deleteNoteStatusById(id: string) {
    return this.httpClient.delete(`${this.noteStatusUrl}/${id}`);
  }

  getNoteStatuses(page?: number, size?: number, sort?: number) {
    let queryParams = new HttpParams();
    if (page) {
      queryParams = queryParams.append('page', page);
    }

    if (size) {
      queryParams = queryParams.append('size', size);
    }

    if (sort) {
      queryParams = queryParams.append('sort', sort);
    }

    return this.httpClient.get<ResponseListApi<NoteStatus>>(`${this.noteStatusUrl}`, {
      params: queryParams
    });
  }

  createNoteStatus(body: NoteStatusCreate) {
    return this.httpClient.post(`${this.noteStatusUrl}`, body);
  }
}
