import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayModule } from 'primeng/overlay';

import { LeadNote } from '@core/models/interfaces/lead/lead-notes';
import { PolicyDetails } from '@core/models/interfaces/lead/lead-policys';
import { NoteStatus } from '@core/models/interfaces/note';
import { NoteService } from '@core/services/note.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { LeadStatusDropdownComponent } from '@shared/components/lead-status-dropdown/lead-status-dropdown.component';
import { OutsideClickDirective } from '@shared/directives/outside-click.directive';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { InputTextareaModule } from 'primeng/inputtextarea';

export interface NoteUpdateData {
  id: string;
  statusId: string;
  policyId: string;
  notes: string;
  isPin: boolean;
}

@Component({
  selector: 'app-lead-note-item',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    LeadStatusDropdownComponent,
    CustomAvatarComponent,
    InputTextModule,
    OverlayModule,
    TranslateModule,
    ButtonComponent,
    DropdownModule,
    MenuModule,
    OutsideClickDirective,
    InputTextareaModule,
    OverlayModule,
    TimeAgoPipe
  ],
  templateUrl: './lead-note-item.component.html',
  styleUrl: './lead-note-item.component.scss'
})
export class LeadNoteItemComponent implements OnChanges, AfterViewInit {
  @Input({ required: true }) note: LeadNote;
  @Input() leadId = '';
  @Input() isExpanded = false;
  @Input() statuses: NoteStatus[] = [];
  @Input() policies: PolicyDetails[] = [];

  @Output() onRefresh = new EventEmitter();
  @Output() deleteNote = new EventEmitter();
  @Output() refreshLayout = new EventEmitter();

  @ViewChild('noteElement') noteElement: ElementRef<HTMLDivElement>;
  isLarge = false;
  isViewMore = false;

  readonly translatePrefix = 'lead-details.notes.';
  readonly messagePrefix = 'lead-details.message.';

  actions: MenuItem[] = [
    {
      label: this.translateService.instant('action.edit'),
      icon: 'icon-wl-edit',
      command: () => {
        this.onEdit();
      }
    },
    {
      label: this.translateService.instant('action.delete'),
      icon: 'icon-wl-delete',
      styleClass: 'error-color',
      command: () => {
        this.onDelete();
      }
    }
  ];

  isEdit = false;
  isComment = false;
  isMenu = false;
  visible = false;

  noteValue = '';
  isUpdating = false;
  isPin = false;
  statusInit: NoteStatus;
  statusId = '';
  policyId = '';

  replyNote = '';

  constructor(
    private noteService: NoteService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.checkNoteIsLarge();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['note']?.currentValue) {
      this.policyId = this.note.policyInfoId;
      this.isPin = Boolean(this.note.isPin);
      this.noteValue = this.note.notes;
      this.statusInit = { ...this.note.status };
    }
  }

  checkNoteIsLarge() {
    if (this.noteElement.nativeElement.clientHeight > 85) {
      this.isLarge = true;
    } else {
      this.isLarge = false;
    }
  }

  onCancel() {
    this.isEdit = false;
    this.noteValue = this.note.notes;
    this.statusInit = { ...this.note.status };

    this.refreshLayout.emit();
  }

  onDelete() {
    this.noteService.deleteLeadNoteById(this.note.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(this.messagePrefix + 'delete-note-success')
        });

        this.onRefresh.emit(this.note);
        this.deleteNote.emit();
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'delete-note-failed')
        });
      }
    });
  }

  onEdit() {
    this.isEdit = true;
    this.refreshLayout.emit();
  }

  onUpdate() {
    this.isUpdating = true;
    this.noteService
      .updateLeadNoteById(this.note.id, {
        statusId: this.statusId || this.note.status?.id,
        notes: this.noteValue,
        isPin: this.isPin,
        leadId: this.leadId,
        policyInfoId: this.policyId
      })
      .subscribe({
        next: () => {
          this.isUpdating = false;
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.messagePrefix + 'update-note-success')
          });

          this.isEdit = false;
          this.checkNoteIsLarge();
        },
        error: () => {
          this.isUpdating = false;
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.messagePrefix + 'update-note-failed')
          });
        }
      });
  }

  onReply() {
    if (!this.replyNote) return;

    this.noteService.replyLeadNote(this.note.id, this.replyNote).subscribe({
      next: res => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(this.messagePrefix + 'reply-success')
        });

        this.note.replyNotes?.unshift(res.data);
        this.refreshLayout.emit();
        this.replyNote = '';
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'reply-failed')
        });
      }
    });
  }

  onPin() {
    if (this.isUpdating) return;

    this.isUpdating = true;
    this.isPin = !this.isPin;
    this.noteService
      .updateLeadNoteById(this.note.id, {
        statusId: this.note.status?.id,
        notes: this.note.notes,
        isPin: this.isPin,
        leadId: this.leadId
      })
      .subscribe({
        next: () => {
          // this.messageService.add({
          //   severity: 'success',
          //   detail: this.translateService.instant(
          //     this.messagePrefix + (this.isPin ? 'pin-note-success' : 'unpin-note-success')
          //   )
          // });
          this.isUpdating = false;
          // this.onRefresh.emit();
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(
              this.messagePrefix + (this.isPin ? 'pin-note-failed' : 'unpin-note-failed')
            )
          });
          this.isUpdating = false;
        }
      });
  }

  onAppends() {
    this.isComment = !this.isComment;
    this.refreshLayout.emit();
  }

  onToggleViewMore() {
    this.isViewMore = !this.isViewMore;
    this.refreshLayout.emit();
  }
}
