<div class="border rounded-sm">
  <!-- HEADER -->
  <div class="flex items-center justify-between h-11 pl-2 pr-2.5">
    <app-lead-status-dropdown
      [disabled]="!isEdit"
      [(visible)]="visible"
      [(selected)]="statusId"
      [options]="statuses"
      [initValue]="statusInit"
      [isApplyBtn]="false"
      [showIcon]="true"
      inputClass="h-7 border-none w-fit !justify-start px-2"></app-lead-status-dropdown>

    <!-- ICONS -->
    <div class="flex items-center">
      <div class="w-7 h-7 flex items-center justify-center cursor-pointer" (click)="onPin()">
        @if (isPin) {
          <img src="/assets/images/icons/solid-pin.svg" alt="Icon pin" />
        } @else {
          <i class="text-4 icon-wl-pin"></i>
        }
      </div>
      <div
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        [ngClass]="isMenu ? 'rounded-sm bg-neutral-200' : ''"
        (click)="menu.toggle($event)">
        <i class="icon-wl-dots-horizontal text-4 cursor-pointer"></i>
      </div>

      <p-menu appendTo="body" #menu [model]="actions" [popup]="true" />
    </div>
  </div>

  <div class="flex flex-col pb-3 px-4">
    <!-- BODY -->

    <div class="relative" #noteElement>
      @if (isEdit) {
        <textarea
          [rows]="1"
          class="!shadow-none border-none p-0 w-full"
          pInputTextarea
          [autoResize]="true"
          (focus)="onEdit()"
          (input)="refreshLayout.emit()"
          [(ngModel)]="noteValue"></textarea>
      } @else {
        <div class="break-words" [ngClass]="isLarge && !isViewMore ? 'line-clamp-4' : ''">
          {{ noteValue }}
        </div>
        @if (isLarge && !isEdit) {
          <span (click)="onToggleViewMore()" class="text-blue-600 hover:text-blue-700 cursor-pointer">{{
            (isViewMore ? 'action.view-less' : 'action.view-more') | translate
          }}</span>
        }
      }
    </div>

    <div class="mt-2 -ml-3">
      <p-dropdown
        appendTo="body"
        [disabled]="!isEdit"
        [readonly]="!isEdit"
        [filter]="true"
        [options]="policies"
        [(ngModel)]="policyId"
        optionValue="id"
        optionLabel="number"
        [placeholder]="translatePrefix + 'policy-info' | translate"
        styleClass="h-7 border-none !shadow-none px-0"
        dropdownIcon="icon-wl-arrow-drop-down"></p-dropdown>
    </div>

    @if (!isEdit) {
      <!-- FOOTER -->
      <div class="flex items-center justify-between text-2 mt-3">
        <div class="flex items-center gap-1.5">
          <app-custom-avatar
            [imgId]="note.createdBy?.avatar || ''"
            [name]="note.createdBy?.fullName || ''"
            size="2xs"></app-custom-avatar>
          <span class="font-semibold max-w-15 truncate">{{ note.createdBy?.fullName }}</span>
          <div class="w-1 h-1 rounded-full bg-neutral-alpha-500A"></div>
          <span (click)="onAppends()" class="text-primary-600 cursor-pointer">
            {{ note.replyNotes?.length }}
            {{ (note.replyNotes.length > 1 ? 'common.appends' : 'common.append') | translate }}
          </span>
        </div>
        <div class="text-neutral-600">
          @if (isExpanded) {
            {{ note?.createdAt | date: 'MM/dd/yyyy hh:mm' }}
          } @else {
            {{ note?.createdAt | date: 'MM/dd/yyyy' }}
          }
        </div>
      </div>
    }
  </div>

  <!-- COMMENTS -->
  @if (isEdit) {
    <div class="h-12 flex items-center justify-end px-3 gap-2 border-t">
      <app-button
        [label]="'action.cancel' | translate"
        variant="ghost-secondary"
        size="xs"
        (onClick)="onCancel()"></app-button>
      <app-button [label]="'action.save' | translate" size="xs" (onClick)="onUpdate()"></app-button>
    </div>
  } @else {
    @if (isComment) {
      <div class="py-3 border-t">
        <div class="flex flex-col gap-2.5 max-h-[200px] overflow-y-auto px-4">
          @for (reply of note.replyNotes; track reply.id) {
            <div class="flex flex-col gap-1">
              <div class="flex items-center gap-1.5">
                <app-custom-avatar
                  [imgId]="reply?.createdBy?.avatar || ''"
                  [name]="reply?.createdBy?.fullName || ''"
                  size="2xs"></app-custom-avatar>
                <span class="text-2 font-semibold flex-1">{{ reply?.createdBy?.fullName }}</span>
                <span class="text-2 text-neutral-600">
                  @if (isExpanded) {
                    {{ reply?.createdAt | date: 'MM/dd/yyyy hh:mm' }}
                  } @else {
                    {{ reply?.createdAt | date: 'MM/dd/yyyy' }}
                  }
                </span>
              </div>
              <div class="ml-5.5 break-words">{{ reply?.notes }}</div>
            </div>
          }

          <div class="flex items-center gap-1.5">
            <app-custom-avatar imgId="" name="Jack Vo" size="2xs"></app-custom-avatar>
            <input
              [(ngModel)]="replyNote"
              class="h-9 !shadow-none focus:border-blue-600"
              type="text"
              pInputText
              [placeholder]="translatePrefix + 'enter-note' | translate"
              (keyup.enter)="onReply()" />
          </div>
        </div>
      </div>
    }
  }
</div>
