import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatusOption } from '@core/models/interfaces/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { OverlayModule } from 'primeng/overlay';

@Component({
  selector: 'app-lead-status-dropdown',
  standalone: true,
  imports: [OverlayModule, ButtonComponent, TranslateModule, CommonModule],
  templateUrl: './lead-status-dropdown.component.html',
  styleUrl: './lead-status-dropdown.component.scss'
})
export class LeadStatusDropdownComponent {
  @Input({ required: true }) visible = false;
  @Input({ required: true }) selected: string = '';
  @Input({ required: true }) options: StatusOption[] = [];
  @Input() disabled = false;
  @Input() initValue?: StatusOption;
  @Input() inputClass = '';

  @Input() isApplyBtn = true;
  @Input() showIcon = true;

  @Output() selectedChange = new EventEmitter<string>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onApplyStatus = new EventEmitter<StatusOption>();

  onToggleVisible() {
    if (this.disabled) return;
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  onHide() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  onChangeStatus(status: StatusOption) {
    this.selected = status.id;
    this.selectedChange.emit(this.selected);
    if (!this.isApplyBtn) {
      this.initValue = status;
      this.onHide();
      this.onApplyStatus.emit();
    }
  }

  onApply() {
    this.onApplyStatus.emit();
  }
}
